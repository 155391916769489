import { useQuery } from '@blitzjs/rpc';
import NiceModal from '@ebay/nice-modal-react';
import getConferenceBuddyParticipants from '@gn/core/conference-buddy/queries/getConferenceBuddyParticipants';
import { Brand, Event } from '@gn/db/client';
import { trackEvent } from 'app/analytics';
import AddEventButton from 'app/core/components/buttons/AddEventButton';
import { format } from 'date-fns';
import { ComponentProps, useContext } from 'react';
import { CurrentUserContext } from '../CurrentUserContext';
import ConferenceBuddySection from '../event/ConferenceBuddySection';
import ShareBadgeSection from '../event/ShareBadgeSection';
import NicePortalModal from './adapter/NicePortalModal';

export interface RegistrationFinishedModalProps {
    event: ComponentProps<typeof ShareBadgeSection>['event'] &
        ComponentProps<typeof ConferenceBuddySection>['event'] &
        Pick<Event, 'startDate' | 'isConferenceBuddiesEnabled'> & { brand: Pick<Brand, 'domain' | 'name'> };
    showBuddyButton?: boolean;
}

export const RegistrationFinishedModal = NiceModal.create(
    ({ event, showBuddyButton }: RegistrationFinishedModalProps) => {
        const { startDate, brand } = event;

        const user = useContext(CurrentUserContext);

        const [participants] = useQuery(
            getConferenceBuddyParticipants,
            { eventId: event.id },
            { enabled: showBuddyButton },
        );

        return (
            <NicePortalModal>
                {({ onClose }) => (
                    <>
                        <p className="popup__text">
                            Event will be held at {format(startDate, 'HH:mm')} on {format(startDate, 'do MMM y')}.{' '}
                            {brand.domain && (
                                <>
                                    <br />
                                    You can always find more details at the{' '}
                                    <a href={brand.domain} className="link link--danger">
                                        {`${brand.name}`}{' '}
                                    </a>
                                    event page on the portal.
                                </>
                            )}
                        </p>

                        <div className="popup__brames brames">
                            <div className="brames__text">Do not miss the event!</div>
                            <div className="brames__button">
                                <AddEventButton
                                    event={{
                                        ...event,
                                        title: event.name,
                                    }}
                                    onClick={() => {
                                        trackEvent({
                                            action: 'click-add-event-to-calendar',
                                        });
                                    }}
                                    className="btn--md"
                                >
                                    Add to calendar
                                </AddEventButton>
                            </div>
                        </div>

                        {showBuddyButton && (
                            <ConferenceBuddySection className="cb-program" event={event} participants={participants} />
                        )}

                        <ShareBadgeSection event={event} user={user!} className="popup__sharing">
                            <div className="popup__close-link">
                                <a href="#" onClick={onClose}>
                                    Close
                                </a>
                            </div>
                        </ShareBadgeSection>
                    </>
                )}
            </NicePortalModal>
        );
    },
);
