import { Event, User } from '@gn/db/client';
import classNames from 'classnames';
import ConferenceBuddyParticipateButton from '../buttons/conference-buddy/ConferenceBuddyParticipateButton';
import { getAvatar } from 'app/users/utils';

interface ConferenceBuddySectionProps {
    event: Pick<Event, 'id' | 'name' | 'slug'>;
    participants?: Pick<User, 'id' | 'nickname' | 'name' | 'avatar'>[];
    className?: string;
}

export default function ConferenceBuddySection({ event, participants, className }: ConferenceBuddySectionProps) {
    return (
        <>
            <div className={classNames('cb-programm', className)}>
                <div className="cb-programm__title title3">Conference buddy program</div>

                <p>
                    Meeting other talented people is a major value one can get from attending a conference. We propose
                    you participate in a conference buddy program to be introduced to other attendees and experience the
                    conference together.
                </p>

                {participants && participants.length > 0 && (
                    <>
                        <p>They are already in:</p>
                        <div className="cb-speakers">
                            {participants.map((p) => (
                                <div className="cb-speakers__item" key={p.id}>
                                    <a href={`/person/${p.nickname || p.id}`} target="_blank">
                                        <img src={getAvatar(p)} alt={`${p.name} avatar`} />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

            <div className="ty-registering__brames brames brames--vertical">
                <div className="brames__text">Meet new friends and new opportunities</div>
                <div className="brames__button">
                    <ConferenceBuddyParticipateButton event={event} />
                </div>
            </div>
        </>
    );
}
