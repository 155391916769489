import React, { useState } from 'react';
import { invalidateQuery, useMutation } from '@blitzjs/rpc';

import getUserAchievements from 'app/users/queries/getUserAchievements';
import { refetchUser } from '../../CurrentUserContext';
import { AchievementsNotificationModal } from '../components';
import { AchievementsContextValue, AchievementsContext } from './constants';
import processAchievementEvent from 'app/achievements/mutations/processAchievementEvent';

export const AchievementsProvider = ({ children }) => {
    const [receivedAchievementData, setReceivedAchievementData] = useState<
        AchievementsContextValue['achievementNotification']
    >(null);

    const [processAchievementEventMutation] = useMutation(processAchievementEvent, {});

    const achieve: AchievementsContextValue['achieve'] = async (event, payload) => {
        const { data } = await processAchievementEventMutation({ event, ...payload } as any);
        await invalidateQuery(getUserAchievements);
        refetchUser();

        if (data) {
            setReceivedAchievementData(data);
        }
    };

    const clear = () => {
        setReceivedAchievementData(null);
    };

    return (
        <AchievementsContext.Provider
            value={{
                achievementNotification: receivedAchievementData,
                achieve,
                clearNotification: clear,
            }}
        >
            <AchievementsNotificationModal />
            {children}
        </AchievementsContext.Provider>
    );
};
