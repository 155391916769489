import { useQuery } from '@blitzjs/rpc';
import BalanceCoinIcon from 'app/core/components/icons/BalanceCoinIcon';
import getAchievements from 'app/achievements/queries/getAchievements';

import { AchievementIcon } from '../AchievementIcon';

interface AchievementsListItemProps {
    id: number;
    name: string;
    iconUrl: string;
    value: number;
    description?: string | null;
}

const AchievementsListItem = ({ name, iconUrl, value, description }: AchievementsListItemProps) => {
    return (
        <li className="achievements-list__item">
            <span className="achievements-list__item__icon">
                <AchievementIcon name={name} iconUrl={iconUrl} />
            </span>

            <span className="achievements-list__item__name">{name}</span>

            <span className="achievements-list__item__value">
                <BalanceCoinIcon />
                {value}
            </span>

            {description && (
                <div className="achievements-list__item__tooltip">
                    <span className="achievements-list__item__tooltip__icon">
                        <AchievementIcon name={name} iconUrl={iconUrl} />
                    </span>

                    <div>
                        <span>{name}</span>
                        <p>{description}</p>
                    </div>
                </div>
            )}
        </li>
    );
};

export const AchievementsList = () => {
    const [achievements] = useQuery(getAchievements, {});

    return (
        <ul className="achievements-list">
            {achievements?.map((itemData) => (
                <AchievementsListItem key={itemData.id} {...itemData} />
            ))}
        </ul>
    );
};
