import { createContext } from 'react';
import { Achievement } from '@gn/db/client';
import { AchievementEvent } from '@gn/core/achievements';

export type AchievementsContextValue = {
    achievementNotification: Pick<Achievement, 'id' | 'name' | 'iconUrl' | 'value'> | null;
    achieve: (
        event: AchievementEvent,
        payload?: { eventRegistrationId?: number; commentId?: number; mentorshipRequestId?: number },
    ) => void;
    clearNotification: () => void;
};

export const AchievementsContext = createContext<AchievementsContextValue>({
    achievementNotification: null,
    achieve: () => void {},
    clearNotification: () => void {},
});
