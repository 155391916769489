import { useContext } from 'react';
import { TagComponent } from './TagComponent';
import { CurrentUserContext } from '../CurrentUserContext';
import { useNewContentCounter } from '../../hooks/useNewContentCounter';

export function CurrentUserInterests({ selectedTag }) {
    const currentUser = useContext(CurrentUserContext);
    const { newContentByTagsIdCounts } = useNewContentCounter(currentUser);
    if (currentUser && currentUser.interests?.length)
        return (
            <>
                <div className="aside-block__item">
                    <div className="aside-subtitle">Subscribed</div>
                    <ul className="tags-list tags-list--lg">
                        {currentUser.interests.map(({ tag }) => (
                            <TagComponent
                                key={tag.id}
                                selectedTag={selectedTag}
                                tag={tag}
                                userNewContentTags={newContentByTagsIdCounts}
                            />
                        ))}
                    </ul>
                </div>
            </>
        );
    return null;
}

export function MenuTagsList({ tags, selectedTag }) {
    return tags && tags.length ? (
        <div className="aside-block">
            <CurrentUserInterests selectedTag={selectedTag} />
            <div className="aside-block__item">
                <div className="aside-subtitle">Popular</div>
                <div className="tags-list">
                    {tags?.map((tag) => (
                        <TagComponent key={tag.id} selectedTag={selectedTag} tag={tag} prefetch noFollow />
                    ))}
                </div>
            </div>
        </div>
    ) : null;
}
