import { useContext } from 'react';
import { AchievementsMerchCard, AchievementsMerchCardProps } from '../AchievementsMerchCard';
import { MERCH_LIST_DATA } from './constants';
import { CurrentUserContext } from 'app/core/components/CurrentUserContext';

export interface AchievementsMerchListProps {
    data?: AchievementsMerchCardProps[];
}

export const AchievementsMerchList = ({ data = MERCH_LIST_DATA }: AchievementsMerchListProps) => {
    const user = useContext(CurrentUserContext);

    const balance = user?.balance ?? 0;

    return (
        <ul className="achievement-merch-list">
            {data.map((itemData) => (
                <li key={itemData.id}>
                    <AchievementsMerchCard disabled={itemData.price > balance} {...itemData} />
                </li>
            ))}
        </ul>
    );
};
