import { useContext, useEffect, useRef } from 'react';
import { trackEvent } from 'app/analytics';
import BalanceCoinIcon from 'app/core/components/icons/BalanceCoinIcon';
import { CurrentUserContext } from 'app/core/components/CurrentUserContext';
import { formatNumber } from '../../utils/format-number';
import { AchievementsModal } from '../AchievementsModal';
import { AchievementsProgressIndicator } from '../AchievementsProgressIndicator';

export const AchievementsButton = () => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const user = useContext(CurrentUserContext);

    const balance = user?.balance ?? 0;

    useEffect(() => {
        const handleEvent = () => {
            buttonRef.current?.click();
        };

        window.addEventListener('achievementsModal', handleEvent);

        return () => window.removeEventListener('achievementsModal', handleEvent);
    }, []);

    return (
        <AchievementsModal
            trigger={(handleOpenModal) => (
                <button
                    ref={buttonRef}
                    className="achievements__button"
                    onClick={() => {
                        trackEvent({
                            action: 'click-open-achievements-modal',
                        });
                        handleOpenModal();
                    }}
                >
                    <AchievementsProgressIndicator value={balance} />

                    <span className="achievements__button__label">
                        <BalanceCoinIcon />

                        <span>{formatNumber(balance)}</span>
                    </span>
                </button>
            )}
        />
    );
};
