import { useContext } from 'react';
import { trackEvent } from 'app/analytics';
import { AchievementsProgressIndicator } from '../AchievementsProgressIndicator';
import { formatNumber } from '../../utils/format-number';
import BalanceCoinIcon from 'app/core/components/icons/BalanceCoinIcon';
import { CurrentUserContext } from 'app/core/components/CurrentUserContext';

const MAX_AVAILABLE_BALANCE = formatNumber(5000);

export const AchievementsBalanceWidget = () => {
    const user = useContext(CurrentUserContext);

    const balance = user?.balance ?? 0;

    const handleLearnAboutClick = () => {
        trackEvent({
            action: 'click-achievements-learn-about-citizenship',
        });
    };

    return (
        <div className="achievements-balance-widget">
            <div className="achievements-balance-widget__balance">
                <h3 className="achievements-balance-widget__heading">Your balance</h3>

                <div className="achievements-balance-widget__balance__value">
                    <BalanceCoinIcon />
                    <span>{formatNumber(balance)}</span>
                </div>
            </div>
            <div className="achievements-balance-widget__progress-block">
                <div>
                    <h3 className="achievements-balance-widget__heading">Citizenship progress</h3>

                    <a href="https://gitnation.org/#visas" target="_blank" onClick={handleLearnAboutClick}>
                        Learn about
                    </a>
                </div>

                <div className="achievements-balance-widget__progress">
                    <span className="achievements-balance-widget__progress__range-label">0</span>

                    <AchievementsProgressIndicator value={balance} />

                    <span className="achievements-balance-widget__progress__range-label">{MAX_AVAILABLE_BALANCE}</span>
                </div>
            </div>
        </div>
    );
};
