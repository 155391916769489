import cn from 'classnames';
import Link from 'next/link';
import { formatEventDate } from '@gn/core/event/utils';
import { getEventLink, sanitizeEventDescription } from '../../events/utils';
import { CurrentUserContext } from './CurrentUserContext';
import React, { useContext } from 'react';
import { isMultipassValid } from '@gn/core/multipass/utils';
import { EventLabelWithReg } from './EventLabelWithReg';
import { EventWithBrand, Promocode } from '@gn/core/type';
import { getResizedCloudinaryImage } from '@gn/core/integrations/cloudinary';
import FollowUs from './FollowUs';
import useEventRegistration from '../hooks/useEventRegistration';
import useEventAccess from '../hooks/useEventAccess';
import { useQuery } from '@blitzjs/rpc';
import getActivePromocodes from 'app/promocodes/queries/getActivePromocodes';
import { MultipassCard } from './MultipassCard';
import { NewTabIcon } from './icons/NewTabIcon';

interface EventListProps {
    className?: string;
    events: EventWithBrand[];
    labelFunction?: (event: EventWithBrand) => React.ReactElement | null;
    horizontal?: boolean;
    grid?: boolean;
    smilesBackground?: boolean;
    pastEvents?: boolean;
    large?: boolean;
    wrapper?: boolean;
    isAnimation?: boolean;
    brandDomainUrl?: boolean;
}

export const EventsList = ({
    events,
    labelFunction,
    horizontal,
    smilesBackground,
    grid,
    large,
    wrapper,
    isAnimation = false,
    brandDomainUrl = false,
}: EventListProps) => {
    const [promocodes] = useQuery(getActivePromocodes, {});

    const eventsList = events.map((event) => {
        const promocode = promocodes?.find((promocode) => promocode.eventId === event.id);

        return (
            <React.Fragment key={event.id}>
                <EventListItem
                    event={event}
                    labelFunction={labelFunction}
                    key={event.id}
                    wrapper={wrapper}
                    isAnimation={isAnimation}
                    promocode={promocode}
                    brandDomainUrl={brandDomainUrl}
                />
            </React.Fragment>
        );
    });

    const classes = cn({
        'events-list': true,
        'events-list--large': large,
        'events-list--horizontal': horizontal,
        'events-list--smiles-bg': smilesBackground,
        'events-list--grid': grid,
        'row row-margin': wrapper,
    });

    return <div className={classes}>{eventsList}</div>;
};

interface EventListItemProps {
    event: EventWithBrand;
    labelFunction?: (event: EventWithBrand) => React.ReactElement | null;
    isImgLazyLoading?: boolean;
    wrapper?: boolean;
    promocode?: Promocode | null;
    isAnimation?: boolean;
    brandDomainUrl?: boolean;
}

export const EventListItem = ({
    event,
    labelFunction,
    isImgLazyLoading,
    wrapper,
    promocode,
    isAnimation,
    brandDomainUrl,
}: EventListItemProps) => {
    const user = useContext(CurrentUserContext);

    const isFutureEvent = event.endDate > new Date();
    const isMultipassHolder = !!user && isMultipassValid(user);
    const shouldOpenConferencePage = !isMultipassHolder && brandDomainUrl;

    const elements = (
        <div
            className={cn('card-event', {
                'card-event--promoted': isAnimation && event.isPromoted,
                'card-event--discount': isAnimation && promocode,
            })}
        >
            <Link
                href={shouldOpenConferencePage ? event.brand.domain! : getEventLink(event)}
                prefetch={false}
                className="card-event__link"
                target={shouldOpenConferencePage ? '_blank' : undefined}
            ></Link>
            <div className="card-event__body">
                <div className="card-event__img">
                    <img
                        src={getResizedCloudinaryImage(event.brand.icon!)}
                        alt={`${event.name} logo`}
                        loading={isImgLazyLoading ? 'lazy' : undefined}
                    />
                    {isFutureEvent && <EventStatus event={event} />}
                    {isAnimation && promocode && <EventPromocode promocode={promocode} />}
                </div>
                <div className="card-event__title">
                    {event.name}
                    {shouldOpenConferencePage && <NewTabIcon className="icon-new-tab" />}
                </div>
                <div className="card-event__date">{formatEventDate(event)}</div>
                {event.description && (
                    <div
                        className="card-event__text"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeEventDescription(event.description),
                        }}
                    />
                )}
            </div>
            {labelFunction ? labelFunction(event) : <EventLabelWithReg event={event} brandDomainUrl={brandDomainUrl} />}
        </div>
    );

    return wrapper ? <div className="row-margin__item col-xxl-4 col-md-6">{elements}</div> : elements;
};

export const EventStatus = ({ event }: { event: EventWithBrand }) => {
    const { registration } = useEventRegistration(event.id);
    const { type, ticket } = useEventAccess(event);

    if (registration?.status === 'Canceled') {
        return <div className="card-event__status card-event__status--canceled">Cancelled registration</div>;
    }

    if (registration?.status === 'Pending') {
        return <div className="card-event__status card-event__status--pending">Pending registration</div>;
    }

    if (registration?.status === 'Completed' || type === 'ticket') {
        return (
            <div className="card-event__status card-event__status--success">
                {ticket?.format === 'InPerson' || ticket?.format === 'Hybrid'
                    ? 'Attending in-person'
                    : 'Attending remotely'}
            </div>
        );
    }
    return null;
};

export const EventPromocode = ({ promocode }: { promocode: Promocode }) => {
    return (
        <div className="card-event__status ">
            Save {promocode.percent}% with {promocode.promocode}
        </div>
    );
};

// !!! Use this component before the main content on pages to prevent layout shifts.
// Layout shifts are caused by streaming rendering.
// This is fixed by the hack with placing UpcomingEvents before the main content and style={{ order: 100 }} below.
export function UpcomingEvents({ events, className }: EventListProps) {
    const currentUser = useContext(CurrentUserContext);
    return (
        <div className={`wrap__aside wrap__aside--right side-event ${className || ''}`} style={{ order: 100 }}>
            <div className="wrap__follow-us">
                <FollowUs />
            </div>

            <div className="aside-title color">Upcoming events</div>
            {(!currentUser || (currentUser && !isMultipassValid(currentUser))) && <MultipassCard />}
            <EventsList events={events} isAnimation brandDomainUrl />
        </div>
    );
}
