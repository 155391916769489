import { EventWithBrand } from '@gn/core/type';
import { Event } from '@gn/db/client';
import classNames from 'classnames';
import { EventsList } from '../EventsList';
import FacebookButton from '../badges/common/FacebookButton';
import LinkedinButton from '../badges/common/LinkedinButton';
import TwitterButton from '../badges/common/TwitterButton';
import LoginButton from '../buttons/LoginButton';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from '../buttons/share';
import { getPreviewImageUrl, getSocialSharedText } from '@gn/core/badges/utils';
import { getBadgeUrl } from '@gn/core/utils/url';

type GetSocialSharedTextEvent = Parameters<typeof getSocialSharedText>[0];

interface ShareBadgeSectionProps {
    event: Pick<Event, 'isRegistrationOpen' | 'slug'> & GetSocialSharedTextEvent;
    backgroundColor?: string;
    events?: EventWithBrand[];
    user: { id: number; email: string; name?: string | null; nickname?: string | null } | null;
    className?: string;
}

function getSharedText(event: GetSocialSharedTextEvent, badgeUrl: string) {
    return getSocialSharedText(event, badgeUrl, { hashtag: true });
}

const BACKGROUND_COLOR = '#1A1A1A';

export default function ShareBadgeSection({
    events,
    backgroundColor = BACKGROUND_COLOR,
    user,
    event,
    className,
    children,
}: React.PropsWithChildren<ShareBadgeSectionProps>) {
    const badgeUrl = user && getBadgeUrl(user, event.slug!);

    return (
        <div className={classNames('sharing', className)} style={{ background: backgroundColor }}>
            {event.isRegistrationOpen && (
                <>
                    <div className="sharing__title title3">
                        {user
                            ? 'Feeling like sharing your participation? Share virtual badge on Twitter, LinkedIn or Facebook!'
                            : 'Sign in and check out your personal badge!'}
                    </div>
                    <div className="sharing__card sd-card">
                        <img
                            src={getPreviewImageUrl({
                                name: user?.name,
                                email: user?.email,
                                background: BACKGROUND_COLOR,
                                eventSlug: event.slug!,
                            })}
                            alt="Badge image"
                        />
                        <span className="image-loader">
                            <img src="/img/GN_Preloader_orange.gif" alt="preloader" />
                        </span>
                    </div>
                    <div className="sharing__buttons">
                        {!badgeUrl && (
                            <LoginButton>
                                <a className="btn btn--lg">Sign in</a>
                            </LoginButton>
                        )}
                        {badgeUrl && (
                            <>
                                <TwitterShareButton url={badgeUrl} title={getSharedText(event, badgeUrl)}>
                                    <TwitterButton
                                        textTransform="none"
                                        className="social-btn"
                                        borderRadius={'0.5rem'}
                                        as="div"
                                    >
                                        Twitter
                                    </TwitterButton>
                                </TwitterShareButton>
                                <LinkedinShareButton url={badgeUrl} summary={getSharedText(event, badgeUrl)}>
                                    <LinkedinButton
                                        textTransform="none"
                                        className="social-btn"
                                        borderRadius={'0.5rem'}
                                        as="div"
                                    >
                                        Linkedin
                                    </LinkedinButton>
                                </LinkedinShareButton>
                                <FacebookShareButton url={badgeUrl} quote={getSharedText(event, badgeUrl)}>
                                    <FacebookButton
                                        textTransform="none"
                                        className="social-btn"
                                        borderRadius={'0.5rem'}
                                        as="div"
                                    >
                                        Facebook
                                    </FacebookButton>
                                </FacebookShareButton>
                            </>
                        )}
                    </div>
                </>
            )}
            {events && events.length > 0 && (
                <div className="sharing__events">
                    <div className="sharing__title title3">Check out other events</div>

                    <EventsList events={events} grid labelFunction={() => null} />
                </div>
            )}

            {children}
        </div>
    );
}
