import { User } from '@gn/db';
import { useEffect, useState } from 'react';
import { getAvatar, getProfileUrl } from '../../../users/utils';
import logout from '../../../auth/mutations/logout';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { UserTagSelectorPopup } from '../tags-list/TagSelectorPopup';
import { ForwardToConfPopup } from '../forwardToConfPopup';
import { getClientSideQuery } from '@gn/core/utils/client';
import { redirectToRoot } from '../../../utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { setUser, refetchUser } from '../CurrentUserContext';
import { useSession } from '@blitzjs/auth';

export interface UserHeaderMenuProps {
    user?: User;
}

export function UserHeaderMenu({ user }) {
    const router = useRouter();
    const [userTagsAreSet, updateUserTags] = useState(!user.areInterestsSet);
    const session = useSession();
    const ref = useDetectClickOutside({ onTriggered: () => setMenuVisible(false) });
    const hasForwardPopup = !!(
        typeof window !== 'undefined' &&
        getClientSideQuery('forwardTo') &&
        getClientSideQuery('clientEmail')
    );

    const [isMenuVisible, setMenuVisible] = useState(false);
    const isNewUser = (session as Record<string, any>).isNewUser;
    const isTagSelectionVisible =
        !hasForwardPopup &&
        typeof window !== 'undefined' &&
        userTagsAreSet &&
        window.location.pathname !== '/multipass' &&
        window.location.pathname !== '/signup' &&
        !user.areInterestsSet &&
        user.lastLoginAt &&
        !isNewUser;

    const [showForwardingPopup, setShowForwardingPopup] = useState(hasForwardPopup);

    const handleTagSelectorClose = () => {
        refetchUser();
        updateUserTags(false);
    };

    const anyStatusGroupSubscription =
        user &&
        (
            (user.ownedSubscriptions.length && user.ownedSubscriptions) ||
            (user.subscriptions.length && user.subscriptions) ||
            []
        ).find((s) => s.itemQuantity > 1);

    useEffect(() => {
        if (isNewUser) {
            router.replace(getProfileUrl(user));
        }
    }, [session, user]);

    return (
        <>
            <div ref={ref} className="user-block _drop-wrapper">
                <button
                    className="user-block__wrap  _drop-toggle hidden-md"
                    onClick={() => {
                        setMenuVisible(!isMenuVisible);
                    }}
                >
                    <div className="user-block__img">
                        <img src={getAvatar(user)} alt="" />
                    </div>
                    <div className="user-block__name">
                        <span>{user.name}</span>
                        <svg className="icon icon-arrow-down">
                            <use xlinkHref="/img/sprite.svg?v3#arrow-down"></use>
                        </svg>
                    </div>
                </button>

                <div className={`user-block__dropdown _drop-block hidden-md ${isMenuVisible ? '_show' : ''}`}>
                    <ul className="drop-menu">
                        <li>
                            <Link href={getProfileUrl(user)} onClick={() => setMenuVisible(false)}>
                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                My profile
                            </Link>
                        </li>
                        {user.bookmarks?.length > 0 && (
                            <li>
                                <Link href="/my-bookmarks" onClick={() => setMenuVisible(false)}>
                                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                    My bookmarks
                                </Link>
                            </li>
                        )}

                        <li>
                            <Link href="/multipass" onClick={() => setMenuVisible(false)}>
                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                My multipass
                            </Link>
                        </li>
                        {anyStatusGroupSubscription && (
                            <li>
                                <Link href="/my-team" onClick={() => setMenuVisible(false)}>
                                    My team
                                </Link>
                            </li>
                        )}
                        {!!user && (
                            <>
                                <li>
                                    <Link href="/events/my" onClick={() => setMenuVisible(false)}>
                                        My events
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/my-history" onClick={() => setMenuVisible(false)}>
                                        My history
                                    </Link>
                                </li>
                            </>
                        )}
                        <li>
                            <Link href="/profile" onClick={() => setMenuVisible(false)}>
                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                Settings
                            </Link>
                        </li>
                        <li>
                            {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                            <a
                                style={{ cursor: 'pointer' }}
                                role="button"
                                onClick={async () => {
                                    redirectToRoot(router);
                                    await logout(null, {});
                                    setUser(null);
                                }}
                            >
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {isTagSelectionVisible && <UserTagSelectorPopup onClose={handleTagSelectorClose} user={user} />}
            {showForwardingPopup && (
                <ForwardToConfPopup
                    link={`https://${getClientSideQuery('forwardTo')}?email=${getClientSideQuery('clientEmail')}`}
                    isOpen={showForwardingPopup}
                    onClose={() => {
                        setShowForwardingPopup(false);
                        window.history.pushState({}, document.title, window.location.pathname);
                    }}
                />
            )}
        </>
    );
}
