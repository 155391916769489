import { useMutation, useQuery } from '@blitzjs/rpc';
import { Tag, User } from '@gn/db/client';
import React, { useState, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import updateUser from '../../../users/mutations/updateUser';
import { CurrentUserContext } from '../CurrentUserContext';
import getTagsPaginated from '../../../tags/queries/getTagsPaginated';
import PortalModal from '../modals/adapter/PortalModal';
import ArrowLeft from '../icons/ArrowLeftIcon';
import ArrowRight from '../icons/ArrowRightIcon';
import { PromiseReturnType } from 'blitz';
import getTags from 'app/tags/queries/getTags';
import useDebouncedValue from '@gn/core/utils/useDebouncedValue';
import classNames from 'classnames';

type Tags = NonNullable<PromiseReturnType<typeof getTags>>['tags'];

const tagsColors = [
    '#CBFE00',
    '#01FFE0',
    '#7433FF',
    '#FF03C0',
    '#BE123B',
    '#00FE05',
    '#29CCFF',
    '#3039FF',
    '#FF1756',
    '#FFD600',
    '#6DC200',
    '#3A5AFF',
    '#3A5AFF',
    '#AB5201',
    '#FF3E01',
    '#FD8900',
    '#50823F',
];

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

const opacity = 0.6;

function getTagColor(tag: Tag): string | undefined {
    const hexColor = tagsColors[tag.id % tagsColors.length];
    const rgb = hexToRgb(hexColor);
    if (rgb) {
        return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
    }
}

const tagsOnPage = isMobile ? 12 : 30;

function TagItem({ active, tag, onClick }) {
    const style = {
        backgroundColor: getTagColor(tag),
    };
    const classes = active ? 'topics__btn _active' : 'topics__btn';
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span onClick={onClick} className={classes} style={style}>
            {tag.label}
            <span className="topics__icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.99922 17.8002C13.8593 17.8002 17.7992 13.8603 17.7992 9.0002C17.7992 4.14009 13.8593 0.200195 8.99922 0.200195C4.13911 0.200195 0.199219 4.14009 0.199219 9.0002C0.199219 13.8603 4.13911 17.8002 8.99922 17.8002ZM13.077 7.57801C13.5066 7.14844 13.5066 6.45195 13.077 6.02238C12.6475 5.5928 11.951 5.5928 11.5214 6.02238L7.89922 9.64456L6.47704 8.22238C6.04746 7.7928 5.35098 7.7928 4.9214 8.22238C4.49182 8.65195 4.49182 9.34844 4.9214 9.77801L7.1214 11.978C7.55098 12.4076 8.24746 12.4076 8.67704 11.978L13.077 7.57801Z"
                        fill="currentColor"
                    />
                </svg>
            </span>
        </span>
    );
}

export interface TagSelectorComponentProps {
    selectedTags: Tags;
    setSelectedTags: (tags: Tags) => void;
    isSaveButtonVisible?: boolean;
    isSaveButtonDisabled?: boolean;
    isOpen?: boolean;
    isEditing?: boolean;
    deleteTag?: (tag: Tag) => void;
    title?: string;
    subtitle?: string;
}

export function TagSelectorPopup(props: TagSelectorComponentProps) {
    return <TagSelectorPopupComponent isSaveButtonVisible={true} {...props} />;
}

export interface UserTagSelectorComponentProps {
    user: User;
    onClose: () => void;
}

export function UserTagSelectorPopup(props: UserTagSelectorComponentProps) {
    return <UserTagSelectorPopupComponent {...props} />;
}

export function UserTagSelectorPopupComponent(props: UserTagSelectorComponentProps) {
    const [updateUserMutation, { isLoading }] = useMutation(updateUser);
    const user = useContext(CurrentUserContext);

    const [selectedTags, setSelectedTags] = useState<Tags>(user?.interests.map(({ tag }) => tag) || []);

    return (
        <TagSelectorPopupComponent
            isOpen
            isSaveButtonVisible={true}
            isSaveButtonDisabled={isLoading}
            title="Choose your topics of interest"
            subtitle="Once we know what you are interested in we can suggest relevant workshops events or people to get in touch"
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            onCloseAction={async () => {
                if (user) {
                    const newUser = {
                        interests: selectedTags.map((tag) => tag.id).join(','),
                    };

                    await updateUserMutation({
                        data: newUser,
                    });
                }
                props.onClose();
            }}
        />
    );
}

type OnCloseAction = () => void | Promise<void>;

function TagSelectorPopupComponent({
    isEditing,
    selectedTags,
    setSelectedTags,
    isSaveButtonVisible,
    isSaveButtonDisabled,
    onCloseAction,
    isOpen,
    title,
    subtitle,
}: TagSelectorComponentProps & { onCloseAction?: OnCloseAction }) {
    return (
        <PortalModal
            isOpen={isOpen}
            opener={(onOpen) => (
                <button onClick={() => onOpen()} type="button" className="add-button" aria-label="add">
                    <svg
                        className="icon icon-circle-plus-big"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M15 10.5V15M15 15V19.5M15 15H19.5M15 15H10.5M28.5 15C28.5 22.4558 22.4558 28.5 15 28.5C7.54416 28.5 1.5 22.4558 1.5 15C1.5 7.54416 7.54416 1.5 15 1.5C22.4558 1.5 28.5 7.54416 28.5 15Z"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            )}
            onCloseAction={onCloseAction}
        >
            {({ onClose }) => (
                <>
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            {!!title && <h2 className="popup__title">{title}</h2>}
                            {!!subtitle && <p className="popup__descr">{subtitle}</p>}
                        </div>
                    </div>
                    <TagsPopupItems
                        isEditing={isEditing}
                        onClose={onClose}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        isSaveButtonVisible={isSaveButtonVisible}
                        isSaveButtonDisabled={isSaveButtonDisabled}
                    />
                </>
            )}
        </PortalModal>
    );
}

interface TagsPopupItemsProps extends Omit<TagSelectorComponentProps, 'isOpen'> {
    onClose: OnCloseAction;
}

function TagsPopupItems({
    isEditing,
    selectedTags,
    setSelectedTags,
    onClose,
    isSaveButtonVisible,
    isSaveButtonDisabled,
}: TagsPopupItemsProps) {
    let orderBy;

    if (isEditing) {
        orderBy = { label: 'asc' };
    }

    const [currentPage, setCurrentPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const searchTermDebounced = useDebouncedValue<string>(searchTerm);

    const where =
        searchTermDebounced.length > 1
            ? {
                  label: {
                      contains: searchTermDebounced.trim(),
                  },
              }
            : {};

    const [paginatedTags] = useQuery(
        getTagsPaginated,
        {
            where,
            skip: tagsOnPage * currentPage,
            take: tagsOnPage,
            orderBy: orderBy,
        },
        {
            keepPreviousData: true,
        },
    );

    const tagItems =
        paginatedTags?.tags &&
        paginatedTags.tags.map((tag) => {
            const isActive = selectedTags.some((item) => tag.id === item.id);
            return (
                <TagItem
                    key={tag.id}
                    active={isActive}
                    tag={tag}
                    onClick={() => {
                        const tags = isActive
                            ? selectedTags.filter((item) => item.id !== tag.id)
                            : [...selectedTags, tag];
                        setSelectedTags(tags);
                    }}
                />
            );
        });

    return (
        <>
            <form action="#" className="search-wrap search-wrap--modal">
                <div className="search-wrap__controle">
                    <input
                        value={searchTerm}
                        onChange={(event) => {
                            setCurrentPage(0);
                            setSearchTerm(event.target.value);
                        }}
                        type="text"
                        placeholder="Search tag"
                        className="search-wrap__input"
                    />
                    <svg className="icon icon-zoom">
                        <use xlinkHref="/img/sprite.svg?v3#zoom" />
                    </svg>
                </div>
            </form>
            <div className="topics">
                <div className="topics__item">{tagItems}</div>
            </div>
            <div className="topics__bottom">
                <div className="topics__pagination">
                    {currentPage > 0 ? (
                        <span
                            onClick={() => {
                                setCurrentPage(currentPage - 1);
                            }}
                            className="topics__prev swipe-prev"
                        >
                            <ArrowLeft />
                        </span>
                    ) : null}
                    {paginatedTags?.hasMore ? (
                        <span
                            onClick={() => {
                                setCurrentPage(currentPage + 1);
                            }}
                            className="topics__next swipe-next"
                        >
                            <ArrowRight />
                        </span>
                    ) : null}
                </div>
                {isSaveButtonVisible && (
                    <div className="popup-save">
                        <a
                            href="#"
                            className={classNames('btn btn--default', { 'btn--disabled': isSaveButtonDisabled })}
                            onClick={(e) => {
                                e.preventDefault();
                                onClose();
                            }}
                        >
                            Done
                        </a>
                    </div>
                )}
            </div>
        </>
    );
}
