import Link from 'next/link';
import PortalModal from './adapter/PortalModal';
import { Event } from '@gn/db/client';

interface ConferenceBuddyRegisteredModalProps {
    event: Pick<Event, 'name' | 'slug'>;
}

export default function ConferenceBuddyRegisteredModal({ event }: ConferenceBuddyRegisteredModalProps) {
    return (
        <PortalModal id="popup-conference-buddy-success" isOpen className="text-center">
            {({ onClose }) => (
                <>
                    <div className="popup__head">
                        <h2 className="popup__title">Participation confirmed!</h2>
                        <p className="popup__descr">
                            Thank you for stepping in to{' '}
                            <Link href={`/events/${event.slug}`} className="link link--danger" target="_blank">
                                {event.name}
                            </Link>{' '}
                            conference buddy program! We will send you contact details of your buddy closer to the date
                            of the conference
                        </p>
                    </div>

                    <div className="popup__close-link">
                        <a href="#" onClick={onClose}>
                            Close
                        </a>
                    </div>
                </>
            )}
        </PortalModal>
    );
}
