import { Event } from '@gn/db/client';
import dynamic from 'next/dynamic';
import useQueryAction from 'app/core/hooks/useQueryAction';
import getMyParticipation from 'app/conference-buddy/queries/getMyParticipation';
import useAuthenticated from 'app/core/hooks/useAuthenticated';
import { useMutation, useQuery } from '@blitzjs/rpc';
import registerAsConferenceBuddy from 'app/conference-buddy/mutations/registerAsConferenceBuddy';
import { useSession } from '@blitzjs/auth';
import React, { useContext, useState } from 'react';
import UsersIcon from '../../icons/UsersIcon';
import ConferenceBuddyRegisteredModal from '../../modals/ConferenceBuddyRegisteredModal';
import { CurrentUserContext } from '../../CurrentUserContext';
import classNames from 'classnames';
import { PromiseReturnType } from 'blitz';

import { trackEvent } from 'app/analytics';

const ConferenceBuddyProfileInfoModal = dynamic(() => import('../../modals/ProfileInfoUpdateModal'));

interface ConferenceBuddyParticipateButtonButtonProps {
    event: Pick<Event, 'id' | 'name' | 'slug'>;
    registeredText?: string;
    children?: (props: {
        isButtonDisabled: boolean;
        onParticipate: () => Promise<void>;
        registration: PromiseReturnType<typeof getMyParticipation> | null | undefined;
    }) => JSX.Element;
}

export default function ConferenceBuddyParticipateButton({
    event,
    children,
}: ConferenceBuddyParticipateButtonButtonProps) {
    const currentUser = useContext(CurrentUserContext);
    const { userId } = useSession();

    const [isProfileUpdateModalOpen, setProfileUpdateModalOpen] = useState(false);

    const [registration, { isLoading: isMyParticipationLoading, setQueryData }] = useQuery(
        getMyParticipation,
        { eventId: event.id },
        { enabled: !!userId || !!currentUser },
    );
    const [registerMutation, { isLoading: isSubmitting, isSuccess }] = useMutation(registerAsConferenceBuddy);

    const onParticipate = useAuthenticated(async () => {
        try {
            trackEvent({
                action: 'click-participate-in-conference-buddy-programme',
            });

            const registration = await registerMutation({ eventId: event.id });
            setQueryData(registration);
        } catch (error) {
            if (error.name === 'InsufficientDetailsError') {
                setProfileUpdateModalOpen(true);
                return;
            }

            alert(error.message);
            console.error('Failed to register for conference buddy program', error);
        }
    });

    useQueryAction(
        'buddy-program',
        async () => {
            await onParticipate();
        },
        !isMyParticipationLoading && !registration,
    );

    const isButtonDisabled = isSubmitting || !!registration;

    const button =
        typeof children === 'function' ? (
            children({ isButtonDisabled, onParticipate, registration })
        ) : (
            <button
                className={classNames('btn btn--md', { 'btn--disabled': isButtonDisabled })}
                onClick={async () => {
                    await onParticipate();
                }}
                disabled={isButtonDisabled}
            >
                {!!registration ? (
                    'You have already registered'
                ) : (
                    <>
                        <UsersIcon className={'icon-md icon-start'} />
                        Participate in conference buddy programme
                    </>
                )}
            </button>
        );

    return (
        <>
            {button}
            {isSuccess && <ConferenceBuddyRegisteredModal event={event} />}
            {isProfileUpdateModalOpen && (
                <ConferenceBuddyProfileInfoModal
                    onSuccess={onParticipate}
                    onClose={() => setProfileUpdateModalOpen(false)}
                    popupText="Update your personal information to proceed with conference buddy programme participation"
                />
            )}
        </>
    );
}
