import { useMutation } from '@blitzjs/rpc';
import { useModal } from '@ebay/nice-modal-react';
import { trackEvent } from 'app/analytics';
import useEventAccess from 'app/core/hooks/useEventAccess';
import useEventRegistration from 'app/core/hooks/useEventRegistration';
import registerMultipassUserToEvent from 'app/users/mutations/registerMultipassUserToEvent';
import { RegistrationFinishedModal, RegistrationFinishedModalProps } from '../../modals/RegistrationFinishedModal';

type RegisterToEventButtonProps = {
    event: RegistrationFinishedModalProps['event'];
    children: (props: { onRegister: () => Promise<void> }) => JSX.Element;
};

export default function RegisterToEventButton(props: RegisterToEventButtonProps) {
    if (!props.event.isRegistrationOpen) {
        return null;
    }

    return <Button {...props} />;
}

const Button = ({ event, children }: RegisterToEventButtonProps) => {
    const { type } = useEventAccess(event, event.startDate);
    const { refetch, registration, isLoading } = useEventRegistration(event.id);

    const [registerToEventMutation] = useMutation(registerMultipassUserToEvent);

    const successModal = useModal(RegistrationFinishedModal);

    const onRegister = async () => {
        if (type !== 'multipass') {
            alert('Multipass not valid.');
            return;
        }

        trackEvent({
            action: 'click-quick-registration-to-event',
            params: 'pwa',
        });

        const registerEventResult = await registerToEventMutation({ eventId: event.id });
        if (!registerEventResult) {
            alert('Something went wrong, please contact support.');
            return;
        }

        refetch();
        successModal.show({ event });
    };

    const showRegisterButton = !registration && type === 'multipass' && !isLoading;
    if (!showRegisterButton) {
        return null;
    }

    return children({ onRegister });
};
