import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function useQueryAction(actionId: string, action: () => void | Promise<void>, runIf: boolean) {
    const router = useRouter();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const queryAction = searchParams.get('action');

        if (runIf && queryAction === actionId) {
            searchParams.delete('action');
            router.replace({ pathname: window.location.pathname, search: searchParams.toString() }, undefined, {
                shallow: true,
            });
            action();
        }
    }, [router.query, runIf]);
}
