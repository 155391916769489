import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

Modal.setAppElement('#__next');

interface PortalModalProps extends Omit<Modal.Props, 'isOpen'> {
    isOpen?: boolean;
    title?: string | JSX.Element;
    subtitle?: string;
    opener?: (onOpen: () => void) => JSX.Element;
    openerText?: ReactNode;
    onCloseAction?: () => void | Promise<void>;
    innerCloseButton?: boolean;
    fullscreen?: boolean;
    children?: any;
    className?: string;
}

const MODAL_DEFAULT_STYLES = {
    content: {},
    overlay: {
        zIndex: 2147483010,
    },
};

const CloseButton = ({ onClick }) => (
    <button className="popup-content__close" onClick={onClick}>
        <svg
            className="icon icon-close"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </button>
);

const PortalModal: React.FC<PortalModalProps> = ({
    isOpen = false,
    title,
    subtitle,
    children,
    opener,
    openerText,
    style,
    onCloseAction,
    className,
    innerCloseButton,
    fullscreen,
    overlayClassName,
    ...others
}) => {
    const [isModalOpen, setModalOpen] = useState(isOpen);

    const onClose = async () => {
        if (onCloseAction) {
            try {
                await onCloseAction();
                setModalOpen(false);
            } catch (e) {
                console.error(e);
            }
        } else {
            setModalOpen(false);
        }
    };
    const onOpen = () => setModalOpen(true);

    const modalContent = (
        <>
            {innerCloseButton && <CloseButton onClick={onClose} />}
            {typeof children === 'function' ? children({ isOpen: isModalOpen, onOpen, onClose }) : children}
        </>
    );

    return (
        <>
            {opener ? (
                opener(onOpen)
            ) : openerText ? (
                <button className="btn btn--lg" onClick={onOpen}>
                    {openerText}
                </button>
            ) : null}
            <Modal
                isOpen={isModalOpen}
                className="popup-content"
                style={{
                    content: { ...MODAL_DEFAULT_STYLES.content, ...(style?.content || {}) },
                    overlay: { ...MODAL_DEFAULT_STYLES.overlay, ...(style?.overlay || {}) },
                }}
                onRequestClose={onClose}
                shouldCloseOnEsc
                shouldCloseOnOverlayClick
                overlayClassName={cn('popup-wrapper', { 'popup-wrapper--fullscreen': fullscreen }, overlayClassName)}
                {...others}
            >
                {!innerCloseButton && <CloseButton onClick={onClose} />}
                {!!title && (
                    <div className="popup-content__head">
                        <h2 className="popup-content__title">{title}</h2>
                        {!!subtitle && <p className="popup-content__text">{subtitle}</p>}
                    </div>
                )}
                <div className={classNames('popup', className)}>{modalContent}</div>
            </Modal>
        </>
    );
};

export default PortalModal;
