import { UserHeaderMenu } from './UserHeaderMenu';
import { LoginHeaderButton } from './LoginHeaderButton';
import React, { useContext } from 'react';
import { CurrentUserContext } from '../CurrentUserContext';
import { AchievementsButton } from '../achievements';

export function LoginGroup() {
    const user = useContext(CurrentUserContext);
    return (
        <div className="header__us-navbar us-navbar">
            <div className="us-navbar__el">
                {user ? <UserHeaderMenu user={user} /> : <LoginHeaderButton />}
                <AchievementsButton />
            </div>
        </div>
    );
}
