import BalanceCoinIcon from 'app/core/components/icons/BalanceCoinIcon';
import Image from 'next/image';
import { formatNumber } from '../../utils/format-number';
import classNames from 'classnames';
import { trackEvent } from 'app/analytics';
import PortalModal from 'app/core/components/modals/common/PortalModal';
import ShopBagIcon from 'app/core/components/icons/ShopBagIcon';
import { invalidateQuery, useMutation } from '@blitzjs/rpc';
import createMerchOrder from 'app/achievements/mutations/createMerchOrder';
import { useState } from 'react';
import { refetchUser } from 'app/core/components/CurrentUserContext';
import getUserAchievements from 'app/users/queries/getUserAchievements';

export interface AchievementsMerchCardProps {
    id: number;
    name: string;
    iconLink: string;
    price: number;
    disabled?: boolean;
}

export const AchievementsMerchCard = ({ name, iconLink, price, disabled }: AchievementsMerchCardProps) => {
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [createMerchOrderMutation, { isLoading }] = useMutation(createMerchOrder, {
        onSuccess: () => {
            setIsConfirmed(true);
        },
    });

    const handleCardButtonClick = () => {
        trackEvent({
            action: 'click-achievements-merch-card',
        });
    };

    const handleConfirm = async () => {
        trackEvent({
            action: 'click-confirm-order',
        });
        await createMerchOrderMutation({
            name,
            price,
        });
        await invalidateQuery(getUserAchievements);
        refetchUser();
    };

    const handleCancel = () => {
        if (isConfirmed) {
            setIsConfirmed(false);
        }

        trackEvent({
            action: 'click-cancel-order',
        });
    };

    return (
        <>
            <div className={classNames('achievement-merch-card', { 'achievement-merch-card--disabled': disabled })}>
                <div className="achievement-merch-card__img">
                    <Image src={iconLink} alt={name} width={88} height={88} />
                </div>
                <span className="achievement-merch-card__name">{name}</span>
                <span className="achievement-merch-card__price">
                    <BalanceCoinIcon />
                    {formatNumber(price)}
                </span>

                <PortalModal
                    className="achievement-notification-modal"
                    opener={(onOpen) => (
                        <button
                            className={classNames('btn btn--lg btn--block', { 'btn--disabled': disabled })}
                            onClick={() => {
                                onOpen();
                                handleCardButtonClick();
                            }}
                        >
                            Buy
                        </button>
                    )}
                    overlayClassName="achievements-modal__overlay"
                    title="Order Confirmation"
                    onCloseAction={handleCancel}
                >
                    {({ onClose }) => (
                        <>
                            <div className="achievement-notification-modal__icon-wrapper">
                                <div className="achievement-notification-modal__icon">
                                    <ShopBagIcon />
                                </div>
                            </div>
                            <div>
                                <h4 className="achievement-notification-modal__title">Merchandise</h4>
                                <h3 className="achievement-notification-modal__subtitle">{name}</h3>
                            </div>
                            <div className="achievement-notification-modal__wrapper">
                                {isConfirmed ? (
                                    <p className="achievement-notification-modal__title">
                                        The order has been successfully completed. Our manager will contact you shortly
                                        to clarify delivery details.
                                    </p>
                                ) : (
                                    <>
                                        <h4 className="achievement-notification-modal__title">Price</h4>
                                        <div className="achievement-notification-modal__value">
                                            <BalanceCoinIcon />
                                            <span>{formatNumber(price)}</span>
                                        </div>
                                        <button
                                            className={classNames('btn btn--lg', { 'btn--disabled': isLoading })}
                                            onClick={handleConfirm}
                                        >
                                            Confirm purchase
                                        </button>
                                    </>
                                )}

                                <button className="btn btn--line" onClick={onClose}>
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </PortalModal>
            </div>
        </>
    );
};
