import classNames from 'classnames';

const BalanceCoinIcon = ({ className }: { className?: string }) => {
    return (
        <svg className={classNames('icon', 'icon-balance-coin', className)} viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_5133_4221)" />
            <circle cx="16.0436" cy="15.9577" r="15.36" fill="url(#paint1_linear_5133_4221)" />
            <ellipse
                cx="15.9988"
                cy="16.0012"
                rx="12.0066"
                ry="12.0066"
                fill="url(#paint2_linear_5133_4221)"
                fillOpacity="0.7"
            />
            <path
                opacity="0.8"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5291 27.3069C22.1601 27.3069 27.5357 21.9314 27.5357 15.3004C27.5357 12.9749 26.8745 10.8038 25.73 8.96484C27.1632 10.9424 28.0082 13.3741 28.0082 16.0032C28.0082 22.6342 22.6327 28.0098 16.0017 28.0098C11.6961 28.0098 7.91988 25.7435 5.80078 22.3387C7.98246 25.349 11.5271 27.3069 15.5291 27.3069Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8381 7.87212C22.7 5.90586 19.8467 4.70531 16.713 4.70531C10.0819 4.70531 4.7064 10.0808 4.7064 16.7119C4.7064 19.8463 5.9075 22.7002 7.87456 24.8385C5.48844 22.6441 3.99316 19.4961 3.99316 15.9988C3.99316 9.36771 9.36869 3.99219 15.9997 3.99219C19.4963 3.99219 22.6438 5.48685 24.8381 7.87212Z"
                fill="#E16C00"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1829 16C11.1829 18.6601 13.3394 20.8166 15.9995 20.8166C18.6596 20.8166 20.8161 18.6601 20.8161 16H24.3594C24.3594 20.617 20.6165 24.3599 15.9995 24.3599C11.3825 24.3599 7.63965 20.617 7.63965 16H11.1829Z"
                fill="#FFF8DE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0518 21.9124C11.6104 23.6039 13.8298 24.6613 16.2927 24.6613C21.0092 24.6613 24.8327 20.7835 24.8327 16H24.3843C24.1575 20.5782 20.4256 24.2195 15.8552 24.2195C13.6144 24.2195 11.5752 23.3442 10.0518 21.9124ZM12.6087 19.3082C11.8393 18.4276 11.3722 17.2691 11.3722 16H10.9537C11.0676 17.3156 11.6847 18.4848 12.6087 19.3082Z"
                fill="#DD6C04"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5133_4221"
                    x1="9"
                    y1="2"
                    x2="25"
                    y2="29"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF4CD" />
                    <stop offset="0.256864" stopColor="#FFC200" />
                    <stop offset="0.810518" stopColor="#FFA800" />
                    <stop offset="1" stopColor="#FFB601" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_5133_4221"
                    x1="30.0809"
                    y1="8.78966"
                    x2="2.26226"
                    y2="22.4857"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.281861" stopColor="#FFB901" />
                    <stop offset="0.490143" stopColor="#FFEAA2" />
                    <stop offset="0.776816" stopColor="#FCBF0B" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_5133_4221"
                    x1="10.3155"
                    y1="9.0038"
                    x2="22.7524"
                    y2="25.0908"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF8A00" />
                    <stop offset="0.938927" stopColor="#FFAE00" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default BalanceCoinIcon;
