import getUserAchievements from 'app/users/queries/getUserAchievements';
import { useQuery } from '@blitzjs/rpc';
import { AchievementIcon } from '../AchievementIcon';
import { CurrentUserContext } from 'app/core/components/CurrentUserContext';
import { useContext } from 'react';

interface UserAchievementsListItemProps {
    id: number;
    name: string;
    iconUrl: string;
    count: number;
}

const UserAchievementsListItem = ({ name, iconUrl, count }: UserAchievementsListItemProps) => {
    return (
        <li className="user-achievements-list__item">
            <span className="user-achievements-list__item__icon">
                <AchievementIcon name={name} iconUrl={iconUrl} />
            </span>
            {count > 0 && <div className="user-achievements-list__item__badge">x{count}</div>}
        </li>
    );
};

export const UserAchievementsList = () => {
    const user = useContext(CurrentUserContext);
    const [achievements = []] = useQuery(getUserAchievements, {}, { refetchOnMount: true });

    if (!user || achievements.length === 0) return null;

    return (
        <ul className="user-achievements-list">
            {achievements?.map((itemData) => (
                <UserAchievementsListItem key={itemData.id} {...itemData} />
            ))}
        </ul>
    );
};
