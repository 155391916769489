import { useRouter } from 'next/router';
import { useRef } from 'react';
import Link from 'next/link';
import { PORTAL_ORIGIN } from '@gn/core/config';
import cn from 'classnames';
const BUTTON_SEARCH_SUGGESSTION = [
    'growing to senior',
    'state management',
    'improve react performance',
    'Mejores prácticas de TypeScript',
];

export function SearchBlock({ className, suggesstion }: { className?: string; suggesstion?: boolean }) {
    const inputRef = useRef(null);
    const router = useRouter();

    const onSubmit = (value: string) => {
        if (!value) {
            const { query } = router;
            delete query['clientSearchTerm'];
            // go to the page we were on before using the search
            router.push({ pathname: router.pathname, query }, undefined, {
                shallow: true,
            });
            return;
        }

        if (value.length < 3) {
            return;
        }
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        const shallowUrl = `${window.location.origin}/search/${value}`;

        router.push(
            {
                pathname: router.pathname,
                query: {
                    ...router.query,
                    clientSearchTerm: value,
                },
            },
            shallowUrl,
            { shallow: true },
        );
    };

    return (
        <form
            action="#"
            className={cn('search-wrap', className)}
            onSubmit={(e) => {
                e.preventDefault();
                // @ts-ignore
                onSubmit(inputRef?.current?.value);
            }}
        >
            <div className="search-wrap__controle">
                <input
                    type="text"
                    placeholder="Search in any language..."
                    className="search-wrap__input"
                    ref={inputRef}
                />
                <svg className="icon icon-zoom">
                    <use xlinkHref="/img/sprite.svg?v3#zoom"></use>
                </svg>
            </div>
            {suggesstion && (
                <div className="search-wrap__tools">
                    {BUTTON_SEARCH_SUGGESSTION.map((item, index) => (
                        <Link
                            href={`${PORTAL_ORIGIN}/search/${encodeURIComponent(item)}`}
                            key={index}
                            className="btn btn--sm btn--ternary"
                        >
                            {item}
                        </Link>
                    ))}
                </div>
            )}
        </form>
    );
}
