export interface AchievementsProgressIndicatorProps {
    value?: number;
    min?: number;
    max?: number;
}

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

const getOffset = (value: number, max: number) => (value * 100) / max;

export const AchievementsProgressIndicator = ({
    value: valueProp = 0,
    min = 0,
    max = 5000,
}: AchievementsProgressIndicatorProps) => {
    const value = clamp(valueProp, min, max);
    const barOffset = getOffset(value, max);

    return (
        <span className="achievements-progress-indicator">
            <span className="achievements-progress-indicator__track" />
            <span className="achievements-progress-indicator__bar" style={{ width: `${barOffset}%` }} />
        </span>
    );
};
