import { ErrorBoundary } from '@blitzjs/next';
import { useMutation } from '@blitzjs/rpc';
import { EventWithBrand } from '@gn/core/type';
import { trackEvent } from 'app/analytics';
import { getBadgeUrl } from 'app/badges/utils';
import { getEventLink, isStreamPageAvailable } from 'app/events/utils';
import createEventSubscription from 'app/notification-subscriptions/mutations/createEventSubscription';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';
import useAuthenticated from '../hooks/useAuthenticated';
import useEventAccess from '../hooks/useEventAccess';
import useEventRegistration from '../hooks/useEventRegistration';
import RegisterToEventButton from './buttons/event/RegisterToEventButton';
import { RenewMultipassButton } from './buttons/multipass';
import { CurrentUserContext, setUser } from './CurrentUserContext';
import { DoneIcon } from './icons/DoneIcon';
import TicketIcon from './icons/TicketIcon';

interface ButtonProps {
    event: EventWithBrand;
    brandDomainUrl?: boolean;
}

function UpdatesSignupButton({ event }: ButtonProps) {
    const { id: eventId } = event;

    const user = useContext(CurrentUserContext);

    const [createEventSubscriptionMutation] = useMutation(createEventSubscription, {
        onSuccess: async (data) => {
            setUser(
                {
                    ...user,
                    notificationsSubscriptions: [...(user?.notificationsSubscriptions ?? []), data],
                },
                { refetch: false },
            );
        },
    });

    const onClickAuthenticated = useAuthenticated(async (event) => {
        trackEvent({
            action: 'click-subscribe-to-event-reg-opening',
        });
        event?.preventDefault();
        await createEventSubscriptionMutation({ eventId: eventId });
    }, '/events');

    const subscription = user?.notificationsSubscriptions?.find(
        (notificationSubscription) => notificationSubscription.eventId === eventId,
    );
    return subscription ? (
        <div className="card-event__foot">
            <div className="card-event__foot-txt">
                <div className="txt-icon">
                    <DoneIcon />
                </div>
                Subscribed to registration open notification
            </div>
        </div>
    ) : (
        <div className="card-event__foot">
            <div className="card-event__access">
                <button
                    className="btn btn--default"
                    onClick={onClickAuthenticated}
                    title="Get email notification when event registration opens."
                >
                    Sign up for updates
                </button>
            </div>
        </div>
    );
}

function RegisterButton({ event }: ButtonProps) {
    const user = useContext(CurrentUserContext);
    const { registration } = useEventRegistration(event.id);

    return registration && registration.status !== 'Canceled' && user ? (
        <Link href={getBadgeUrl(user, event)} target="_blank" className="btn btn--default">
            <TicketIcon className="icon-sm btn__icon-start" />
            My registration
        </Link>
    ) : (
        <RegisterToEventButton event={event}>
            {({ onRegister }) => (
                <button className="btn btn--default" onClick={onRegister}>
                    Register
                </button>
            )}
        </RegisterToEventButton>
    );
}

function DetailsLink({ event, brandDomainUrl }: ButtonProps) {
    return (
        <Link
            href={brandDomainUrl ? event.brand.domain! : getEventLink(event)}
            className="btn btn--default"
            target={brandDomainUrl ? '_blank' : undefined}
        >
            Details
        </Link>
    );
}

type EventLabelWIthRegProps = {
    event: EventWithBrand;
    brandDomainUrl?: boolean;
};

export function EventLabelWithReg({ event, brandDomainUrl }: EventLabelWIthRegProps) {
    const path = usePathname();
    const { registration } = useEventRegistration(event.id);
    const { hasAccess, reason } = useEventAccess(event, event.startDate);

    if (!event.isRegistrationOpen) {
        return <UpdatesSignupButton event={event} />;
    }

    let content: JSX.Element;
    switch (true) {
        case path !== '/': {
            content = <DetailsLink event={event} />;
            break;
        }
        case (hasAccess || registration?.status === 'Completed') && isStreamPageAvailable(event): {
            content = (
                <a className="btn" target="_blank" href={`/events/${event.slug ?? event.id}/watch`}>
                    Stream Page
                </a>
            );
            break;
        }
        case !hasAccess && reason === 'multipass_expires': {
            content = <RenewMultipassButton />;
            break;
        }
        case !hasAccess && path === '/': {
            content = (
                <Link href="/multipass" className="btn">
                    Get access
                </Link>
            );
            break;
        }
        default: {
            const fallback = <DetailsLink event={event} brandDomainUrl={brandDomainUrl} />;

            content = (
                <ErrorBoundary fallback={fallback}>
                    <RegisterButton event={event} />
                </ErrorBoundary>
            );
        }
    }

    return <div className="card-event__foot">{content}</div>;
}
