import Image from 'next/image';

interface AchievementIconProps {
    iconUrl: string;
    name?: string;
}

export const AchievementIcon = ({ name, iconUrl }: AchievementIconProps) => (
    // eslint-disable-next-line @next/next/no-img-element
    <img alt={name ?? 'achievement'} src={iconUrl} style={{ width: '1em', height: '1em' }} />
);
