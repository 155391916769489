import { useContext } from 'react';

import PortalModal from 'app/core/components/modals/common/PortalModal';
import BalanceCoinIcon from 'app/core/components/icons/BalanceCoinIcon';

import { AchievementIcon } from '../AchievementIcon';
import { formatNumber } from '../../utils/format-number';
import { AchievementsContext } from '../../context/constants';

export const AchievementsNotificationModal = () => {
    const { achievementNotification, clearNotification } = useContext(AchievementsContext);

    if (!achievementNotification) return null;

    const createEmitOpenModalEvent = (onClose: () => void) => () => {
        window.dispatchEvent(new CustomEvent('achievementsModal'));
        onClose();
    };

    return (
        <PortalModal
            isOpen
            className="achievement-notification-modal"
            title="Congratulations!"
            onCloseAction={clearNotification}
        >
            {({ onClose }) => (
                <>
                    <div className="achievement-notification-modal__icon-wrapper">
                        <div className="achievement-notification-modal__icon">
                            <AchievementIcon
                                name={achievementNotification.name}
                                iconUrl={achievementNotification.iconUrl}
                            />
                        </div>
                    </div>
                    <div>
                        <h4 className="achievement-notification-modal__title">Achieved</h4>
                        <h3 className="achievement-notification-modal__subtitle">{achievementNotification.name}</h3>
                    </div>
                    <div className="achievement-notification-modal__wrapper">
                        <h4 className="achievement-notification-modal__title">Your reward</h4>
                        <div className="achievement-notification-modal__value">
                            <BalanceCoinIcon />
                            <span>{formatNumber(achievementNotification.value)}</span>
                        </div>
                        <button className="btn btn--lg" onClick={createEmitOpenModalEvent(onClose)}>
                            Check what you can buy
                        </button>
                        <button className="btn btn--line" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </>
            )}
        </PortalModal>
    );
};
