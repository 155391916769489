import PortalModal from './modals/adapter/PortalModal';

interface ForwardToConfPopupProps {
    link: string;
    onClose: () => void;
    isOpen: boolean;
}

export function ForwardToConfPopup({ link, onClose, isOpen }: ForwardToConfPopupProps) {
    return (
        <PortalModal isOpen={isOpen} onCloseAction={onClose}>
            <h2 className="popup__title">Welcome to GitNation.com</h2>
            <p className="popup__descr">
                This is the place where we host our conference talks recordings, workshops as well as exlusive content
                with our speakers.
                <br />
                Visit Portal after the conference to re-watch your favorite talks and see our next planned events.
            </p>
            <p className="popup__descr">Proceed to the conference Live Stream Page:</p>
            <div className="popup-save">
                <a href={link} className="btn" target="_blank">
                    Watch conference Live
                </a>
            </div>
        </PortalModal>
    );
}
