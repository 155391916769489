import { useModal } from '@ebay/nice-modal-react';
import SimpleModal from '../core/SimpleModal';

export default function NicePortalModal({ children }) {
    const { hide, visible, remove } = useModal();
    return (
        <SimpleModal isOpen={visible} onClose={() => hide()} onAfterClose={() => remove()}>
            {children}
        </SimpleModal>
    );
}
