import Head from 'next/head';
import React, { ReactNode } from 'react';
import { Header } from '../components/header/Header';
import { Footer } from '../components/Footer';
import { LeftMenu, MenuItems } from '../components/LeftMenu';
import { SearchPage } from '../components/SearchPage';
import { CurrentUserProvider } from '../components/CurrentUserContext';
import { UserWithInterestsAndBookmarks } from 'app/types';
import { useRouter } from 'next/router';
import { Tag } from 'app/templates/types/default';
import { IntercomLauncherProvider } from '../components/IntercomLauncherProvider';
import Promocodes from '../components/Promocodes';

import GAUserSetting from '../components/GAUserSetting';
import { PORTAL_ORIGIN } from '@gn/core/config';
import { AchievementsProvider } from '../components/achievements/context/AchievementsProvider';
import NiceModal from '@ebay/nice-modal-react';

type LayoutProps = {
    title?: string | null;
    ogTitle?: string | null;
    description?: string | null;
    keywords?: string | null;
    oGdescription?: string | null;
    ogImage?: string | null;
    author?: string;
    children: ReactNode;
    selectedMenuItem?: MenuItems;
    noIndex?: boolean;
    tags?: Tag[];
    canonicalUrl?: string;
    initialUserData?: UserWithInterestsAndBookmarks;
    localizations?: { url: string; lang: string; default?: boolean }[];
    hideFooter?: boolean;
};

const Layout = ({
    title,
    ogTitle,
    description,
    keywords,
    oGdescription,
    ogImage,
    children,
    selectedMenuItem,
    author,
    noIndex,
    tags,
    canonicalUrl,
    initialUserData,
    localizations,
    hideFooter,
}: LayoutProps) => {
    const router = useRouter();
    const { clientSearchTerm } = router.query;

    const isShowSearchPage = clientSearchTerm && clientSearchTerm.length > 1;

    const pageTitle = !!title ? title : 'GitNation.com';
    const pageDescription = !!description
        ? description
        : 'Home of React and JavaScript insights directly from framework authors, early adopter engineers and big tech from - talk recordings, hands-on workshops and articles.';
    const pageKeywords =
        typeof keywords === 'undefined'
            ? // keywords data does not affect SEO and we're not ranking by these keywords, while this generically applies to whole website
              // ? 'JavaScript learning, top conferences, famous developers, workshops, tutorials, JS coding, developer resources, online training, best practices, industry experts, event highlights, webinars, conference recordings, development courses, workshop archive, sessions, advanced techniques'
              null
            : keywords;

    const ogImagemeta = typeof ogImage === 'undefined' ? `${PORTAL_ORIGIN}/portal-og.png` : ogImage;

    const xDefault = localizations?.find((l) => l.default);
    return (
        <>
            <Head>
                <title>{pageTitle}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:title" content={!!ogTitle ? ogTitle : pageTitle} key="title" />
                <meta name="description" content={pageDescription} />
                {pageKeywords && <meta name="keywords" content={pageKeywords} />}
                <meta
                    property="og:description"
                    content={!!oGdescription ? oGdescription : pageDescription}
                    key="description"
                />
                {ogImagemeta && <meta property="og:image" content={ogImagemeta} />}
                <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
                <meta name="twitter:site" content="@gitnationorg" key="twitter-site" />
                <meta property="og:type" content="website" />
                {author && <meta name="twitter:creator" content={author} key="twitter-creator" />}
                {noIndex && <meta name="robots" content="noindex" />}
                {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
                {localizations && (
                    <>
                        {localizations.map(({ lang, url }) => (
                            <link key={lang} rel="alternate" href={url} hrefLang={lang.toLowerCase()} />
                        ))}
                        {xDefault && <link rel="alternate" href={xDefault.url} hrefLang="x-default" />}
                    </>
                )}
            </Head>
            <IntercomLauncherProvider>
                <CurrentUserProvider initialData={initialUserData}>
                    <AchievementsProvider>
                        <NiceModal.Provider>
                            <div className="main-wrapper">
                                <Header selectedMenuItem={selectedMenuItem} />

                                <main className="main">
                                    <div className="container">
                                        <div className="wrap">
                                            {isShowSearchPage ? (
                                                <>
                                                    <SearchPage searchTerm={(clientSearchTerm as string) || ''} />
                                                    <LeftMenu tags={tags} selectedMenuItem={selectedMenuItem} />
                                                </>
                                            ) : (
                                                children
                                            )}
                                        </div>
                                    </div>
                                </main>
                                <Promocodes className="main-wrapper__alert" />
                                {!hideFooter && <Footer />}
                                <GAUserSetting />
                            </div>
                        </NiceModal.Provider>
                    </AchievementsProvider>
                </CurrentUserProvider>
            </IntercomLauncherProvider>
        </>
    );
};

export default Layout;
