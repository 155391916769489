import classNames from 'classnames';

export const NewTabIcon = ({ className }: { className?: string }) => {
    return (
        <svg
            className={classNames('icon', 'icon-link', className)}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M9.64922 0.449997C9.01409 0.449997 8.49922 0.964869 8.49922 1.6C8.49922 2.23512 9.01409 2.75 9.64922 2.75H12.6229L5.38605 9.98682C4.93694 10.4359 4.93694 11.1641 5.38605 11.6132C5.83515 12.0623 6.56329 12.0623 7.01239 11.6132L14.2492 4.37634V7.35C14.2492 7.98512 14.7641 8.5 15.3992 8.5C16.0343 8.5 16.5492 7.98512 16.5492 7.35V1.6C16.5492 0.964869 16.0343 0.449997 15.3992 0.449997H9.64922Z" />
            <path d="M2.74922 2.75C1.47896 2.75 0.449219 3.77974 0.449219 5.05V14.25C0.449219 15.5203 1.47896 16.55 2.74922 16.55H11.9492C13.2195 16.55 14.2492 15.5203 14.2492 14.25V10.8C14.2492 10.1649 13.7343 9.65 13.0992 9.65C12.4641 9.65 11.9492 10.1649 11.9492 10.8V14.25H2.74922V5.05L6.19922 5.05C6.83435 5.05 7.34922 4.53512 7.34922 3.9C7.34922 3.26487 6.83435 2.75 6.19922 2.75H2.74922Z" />
        </svg>
    );
};
